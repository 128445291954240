// extracted by mini-css-extract-plugin
var _1 = "rCAmw2fxY7";
var _2 = "cCOjS3BavV";
var _3 = "YrNDvtl4ws";
var _4 = "ucRi2nrYaG";
var _5 = "rNhWySw_ze";
var _6 = "SvddFY93Lq";
var _7 = "sv5jvIVfIT";
var _8 = "TML92adgLy";
var _9 = "v5pXUrzkbt";
var _a = "ncxCwhkFQG";
export { _1 as "cover", _2 as "sizeFill", _3 as "sizeFillAlt", _4 as "sizeFit", _5 as "sizeFull", _6 as "width10", _7 as "width16", _8 as "width24", _9 as "width32", _a as "widthFull" }
